
import CONFIG from 'config';

export const processBoldMarkdown = (text) => {
  return text?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
};

export const processCustomMarkdown = (markdownKey, text, toolData, tool) => {
  console.log("processCustomMarkdown-utils",markdownKey," text->", text," tooldata->", toolData," tool->", tool)
  if(!markdownKey) {
    return text;
  }
  const key=markdownKey?.replace(/[\[\]]/g, '') || '';
  // if(tool=='experts'){
  //  const key = markdownKey?.match(/^\[([a-z0-9]{32})-/)?.[1] || '';
  // }
  

// 0//markdownKey?.replace(/\[|\]/ig, '') || '';
  const citationNum = key || 0;
  let replacementContent = '';
  let output = '';
  console.log("tooldata-key",toolData,"---",key)
  const ref = toolData?.[key];
  console.log("ref",ref);
  switch (tool) {
    case CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL:
      if (ref?.kp_cms_id && ref?.page) {
        replacementContent = `<sup><a href="${CONFIG.UI_URL.PREVIEW_LINK(ref?.kp_cms_id, ref?.page)}" target="_blank">${citationNum}</a></sup> `;
      } 
      output = text.replaceAll(markdownKey, replacementContent);
      console.log("replacementContent",replacementContent);
      console.log("output",output);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL:
      
      replacementContent = markdownKey?.match(/:\s*([^\]]*)/)?.[1] || '';
      const replacementKey = markdownKey?.match(/-(.+?):/)?.[1] || '';

      let replacementLink = '';
      console.log("util-expert", replacementContent," ---",markdownKey,"---",replacementKey);
      if(replacementContent ) {
        const dateFilter = 'Past Two Years';

        switch (replacementKey) {
          case 'Name':
            replacementLink = CONFIG.NAVIGATOR_LINKS.PROFILE(ref?.hrid);
            break;
          case 'Cases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '');
            break;
          case 'recentMaterials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'recentCases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'Materials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '');
        }
        replacementContent = `<a class="expertsLinks" target="_blank" href="${replacementLink}">${replacementContent}</a>`;
         console.log("util-repla",replacementLink,"----",replacementContent)
      }
  
      output = text.replaceAll(markdownKey, replacementContent);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL:
      if (ref?.url) {
        const domain = (new URL(ref?.url))?.hostname;
        replacementContent = domain ? `<sup><a href="${ref?.url}" target="_blank">${domain}</a></sup>` : '';
      }
      output = text.replaceAll(markdownKey, replacementContent);
      break;
  }
  
  return [output, key];
};
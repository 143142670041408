
import axios from 'axios';
import CONFIG from '../../../config';
import { getRandomString } from 'utils/string-mapper/string-mapper';

// Check if the environment is staging and if the agent chat service feature toggle is enabled
export const isUsingAgentsinStaging = CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE && CONFIG.ENVIRONMENT === 'stg';

/**
 * Filters out duplicate sources based on kp_cms_id and slide no.
 * @param {Array} sources - Array of source objects.
 * @returns {Array} - Array of unique source objects.
 */
export const getUniqueSources = (sources) => {
  const seenItems = new Map();
  return sources.filter((source) => {
    const key = `${source.id}-${source.slide}`;
    if (seenItems.has(key)) {
      return false;
    }
    seenItems.set(key, true);
    return true;
  });
};

/**
 * Fetches sources from search based on message sources, request ID, and search query.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} requestId - Unique request ID.
 * @param {string} searchQuery - Search query string.
 * @returns {Array} - Array of verified source objects.
 */
export const fetchSourcesFromSearch = async (messageSources, requestId, searchQuery) => {
  let sources = [];

  try {
    // Check if messageSources is a valid array and not empty
    if (!Array.isArray(messageSources) || messageSources.length === 0) {
      console.info('KNCHAT fetchSourcesFromSearch: No sources found', 'requestId:', requestId);
      return sources;
    }

    // Build the query string from message sources
    const query = buildQuery(messageSources, requestId);
    if (!query) {
      console.error('KNCHAT fetchSourcesFromSearch: Sources do not have valid IDs', 'requestId:', requestId);
      return sources;
    }

    console.warn('KNCHAT fetchSourcesFromSearch in env', CONFIG.ENVIRONMENT, 'query', query, 'requestId:', requestId);

    // Fetch data based on the query and environment
    const data = await fetchData(query, messageSources, searchQuery);
    sources = processResults(data, messageSources, requestId);

    // Log an error if no sources are found
    if (sources.length === 0) {
      console.error('KNCHAT fetchSourcesFromSearch: Source documents not found in search', 'requestId:', requestId);
    }
  } catch (error) {
    console.error('KNCHAT fetchSourcesFromSearch: Error getting sources', error, 'requestId:', requestId);
  }

  return sources;
};

/**
 * Builds a query string from message sources.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} requestId - Unique request ID.
 * @returns {string} - Query string.
 */
const buildQuery = (messageSources, requestId) => {
  const query = messageSources.map(source => {
    if (source?.id?.match(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/i)) {
      return `kp_cms_id:${source.id}`;
    }
    console.error('KNCHAT fetchSourcesFromSearch: Invalid source id provided:', source?.id, 'requestId:', requestId);
    return null;
  }).filter(Boolean).join(' OR ');

  return query;
};

/**
 * Fetches data from the appropriate API based on the environment.
 * @param {string} query - Query string.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} searchQuery - Search query string.
 * @returns {Object} - Data fetched from the API.
 */
const fetchData = async (query, messageSources, searchQuery) => {
  let data;
  if (isUsingAgentsinStaging) {
    const sourceIds = messageSources.map(source => source.id);
    console.log('Sending POST request to GENAI_RETRIEVAL with sourceIds:', sourceIds);
    // Fetch data from the RETRIEVAL API for STG and PROD environments
    data = await axios.post(CONFIG.API_URL.GENAI_RETRIEVAL, {
      query: searchQuery || 'a',
      method: 'hybrid_1',
      top_n: 100,
      consumer_options: {
        consumer_key: 'KN',
        data_source: 'materials'
      },
      request_id: getRandomString(20),
      document_id_filter: {
        kp_cms_id: sourceIds,
        filter_mode: 'include'
      }
    }, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    console.log('Received response from GENAI_RETRIEVAL:', data);
  } else {
    // Fetch data from the KNOWLEDGE_SEARCH API for non-staging environments
    data = await axios.post(CONFIG.API_URL.MATERIAL_POST, { query });
  }
  return data;
};

/**
 * Processes the results from the fetched data and maps them to the message sources.
 * @param {Object} data - Data fetched from the API.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} requestId - Unique request ID.
 * @returns {Array} - Array of processed source objects.
 */
const processResults = (data, messageSources, requestId) => {
  let modifiedResults = [];
  if (isUsingAgentsinStaging) {
    modifiedResults = data.results?.map(result => transformResult(result)) || [];
  }

  return messageSources.map(source => {
    const doc = isUsingAgentsinStaging
      ? modifiedResults.find(doc => doc.kpCmsId === source.id)
      : data.doc?.find(doc => doc.kpCmsId === source.id);

    if (doc) {
      return {
        ...doc,
        id: doc.kpCmsId,
        page: source.slide || 1,
        sourceId: source?.sourceId,
      };
    } else {
      console.error('KNCHAT fetchSourcesFromSearch: Failed to find document, doc:', 'sourceid:', source.id, 'requestId:', requestId);
      return null;
    }
  }).filter(Boolean);
};

/**
 * Transforms a result object to the desired format.
 * @param {Object} result - Result object.
 * @returns {Object} - Transformed result object.
 */
const transformResult = (result) => {
  const dateRevised = result?.date ? new Date(`${result.date}T00:00:00`) : null;

  const newResult = {
    ...result,
    id: result.kp_cms_id,
    docId: result.kp_cms_id,
    kbCmsId: '',
    kpCmsId: result.kp_cms_id,
    docRank: result.rank,
    docType: result.material_type,
    fileName: result.filename,
    materialDesc: result.desc,
    resultType: '',
    materialUrl: '',
    dateRevised: dateRevised,
    globalRelevance: result.relevance,
    materialId: 0,
    hasHtmlPreview: true,
    hasImagePreview: true,
    relevantSlidePath: result.image_path,
    paRecommended: false,
    openAccessInd: true,
    allSubjects: parseJsonArray(result.subject_json, 'subject'),
    allAuthors: parseJsonArray(result.author_json, 'author'),
    functionalAllPAs: parseJsonArray(result.fpa_json, 'fpa'),
    industryAllPAs: parseJsonArray(result.ipa_json, 'ipa'),
  };

  delete newResult.kp_cms_id;
  delete newResult.filename;
  delete newResult.rank;
  delete newResult.material_type;
  delete newResult.date;
  delete newResult.relevance;
  delete newResult.author_json;
  delete newResult.subject_json;
  delete newResult.fpa_json;
  delete newResult.ipa_json;

  return newResult;
};

/**
 * Parses a JSON array and transforms its items based on the type.
 * @param {string} jsonArray - JSON array string.
 * @param {string} type - Type of items in the array.
 * @returns {Array} - Array of transformed items.
 */
const parseJsonArray = (jsonArray, type) => {
  if (!jsonArray) return [];
  try {
    return JSON.parse(`[${jsonArray}]`).map(item => transformItem(item, type));
  } catch (error) {
    console.error(`KNCHAT getSourcesFromSearch: Error parsing ${type} JSON`, error);
    return [];
  }
};

/**
 * Transforms an item based on its type.
 * @param {Object} item - Item object.
 * @param {string} type - Type of the item.
 * @returns {Object} - Transformed item object.
 */
const transformItem = (item, type) => {
  switch (type) {
    case 'subject':
      return {
        attachmentId: '',
        fullPath: item.path?.replace(/>/g, '/') || '',
        hierarchyLevel: 0,
        kpCmsId: '',
        parentId: item.Parent_ID || '',
        subjectId: item.SUBJECT_ID || '',
        subjectName: item.SUBJECT_NAME || '',
        urlId: '',
      };
    case 'author':
      return {
        additionalContact: (item.IsAddtionalContact === 'Y'),
        email: item.Email || '',
        firstName: item.firstname || '',
        hrEmployeeId: item.HR_emp_id || '',
        lastName: item.lastname || '',
        role: '',
        roleSortOrder: item.positionSortOrder || 0,
        staffId: 0,
      };
    case 'fpa':
    case 'ipa':
      return {
        fullPath: item.path?.replace(/>/g, '/') || '',
        hiearchyLevel: 0,
        keywordTopic: item.keywordTopic || '',
        owner: (item.isOwner === 'true'),
        paRecommended: item.ISPARECOMMENDED || false,
        parentId: item.Parent_ID || '',
        topicId: item.Topic_ID || '',
        topicNameAlias: item.Topic_Name_Alias || '',
      };
    default:
      return item;
  }
};